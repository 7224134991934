import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { withTranslation } from "react-i18next"
import LanguagePicker from './languagePicker'
import Link from '../../utilities/link'
import { usePageContext } from "../../pageContext"
import { useTranslation } from "react-i18next"


const SiteNavigation = () => {

    const [showMenu, setShowMenu] = useState(0);

    function activeMobileMenu () {
      setShowMenu(!showMenu)
    }

    const { t } = useTranslation()
    // const menuActive = showMenu ? "is-active" : ""
    // const burgerActive = showMenu ? "is-active" : ""
    const { lang } = usePageContext();
    const mdSearch = useStaticQuery(
      graphql`
        {
          allMarkdownRemark(filter: { frontmatter: { category: { eq: "servizio" }}}) {
            edges {
              node {
                frontmatter {
                  lang
                  translatedPath
                  menuTitle
                  menuTitleTag
                }
              }
            }
          }
        }
      `
    );
  
    return (
      <>
      <header className="navbar is-spaced is-fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link
              to="/"
              title="CTA Manufacturing Home Page"
              className="navbar-item"
            >
              <strong>CTA Manufacturing</strong>
            </Link>
            <span
              className={showMenu ? "navbar-burger burger is-active" : "navbar-burger burger"}
              onClick={activeMobileMenu}
              data-target="navbarMenuHeroC"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroC" className={showMenu ? "navbar-menu is-active" : "navbar-menu"}>
            <div className="navbar-end">
              <Link
                to="/produzione-industriale-in-10-fasi"
                title={t("header.titleTagProduzione")}
                className="navbar-item"
              >
                {t("header.Produzione")}
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <p className="navbar-link">{t('header.Services')}</p>
                <div className="navbar-dropdown">
                  {mdSearch.allMarkdownRemark.edges
                  .filter(edge => edge.node.frontmatter.lang === lang)
                  .map((service) => 
                    <Link
                      to={`/${service.node.frontmatter.translatedPath}`}
                      title={service.node.frontmatter.menuTitleTag}
                      className="navbar-item"
                    >
                      {service.node.frontmatter.menuTitle}
                    </Link>
                  )}
                </div>
              </div>
              <Link
                to="/strumenti-e-macchinari-produttivi"
                title={t("header.titleTagStrumentazione")}
                className="navbar-item"
              >
                {t("header.Strumentazione")}
              </Link>
              <Link to="/blog" title="Blog" className="navbar-item">
                Blog
              </Link>
              <span className="navbar-item">
                <Link
                  to="/contatti"
                  title={t("header.titleTagContatti")}
                  className="button is-info is-outlined"
                >
                  <span> {t("header.Contatti")}</span>
                </Link>
              </span>
              <span className="navbar-item">
                <a
                  href="https://www.ctaelectronics.com"
                  title="CTA Electronics"
                  className="button is-primary is-outlined"
                >
                  <span> {t("header.SitoPrincipale")}</span>
                </a>
              </span>
              <LanguagePicker />
            </div>
          </div>
        </div>
      </header>
      <div className="is-gradient" style={{height: '4px', marginTop: '3.5rem'}}></div>
      </>
    )
  }


export default withTranslation()(SiteNavigation)
