import React from "react"
import { useTranslation } from "react-i18next"
import Link from '../../utilities/link'
import HorizontalSubForm from "../forms/horizontalSubForm"
import FooterNav from "./footerNav"
import CookieDialog from "../common/cookieDialog"

const Footer = () => {

    const { t } = useTranslation()

    return (
      <>
        <CookieDialog />
        <div className="section has-lightgreen-background">
          <div className="container py-24">
            <HorizontalSubForm />
          </div>
        </div>
        <footer
          className="footer"
          style={{ paddingTop: "120px" }}
        >
          <div className="container is-narrow">
            <div className="columns is-multiline">
              <div className="column is-3">
                <div
                  className="title is-4"
                  style={{
                    borderBottom: "1px solid #000",
                    paddingBottom: "20px",
                  }}
                >
                  CTA Manufacturing
                </div>
                <p className="content">{t("footer.descrizione")}</p>
              </div>
              <div className="column is-1"></div>
              <div className="column is-3">
                <div className="content">
                  <h4>{t("footer.sede")}</h4>
                  <p>
                    Via Quarto 26
                    <br />
                    20811 Cesano Maderno (MB)
                    <br />
                    Italia
                    <br />
                    P.IVA (VAT) IT09176980960 <br />
                    CF 09176980960 <br />
                  </p>
                </div>
              </div>
              <div className="column is-3">
                <div className="content">
                  <h4>{t("footer.contatti")}</h4>
                  <p>
                    <strong>Tel</strong> +39 0362 54 11 16 <br />
                    <strong>Email</strong> info[at]ctaelectronics[dot]com <br />
                    <br />
                    <strong>Linkedin</strong>{" "}
                    <a
                      href="https://www.linkedin.com/company/10507408"
                      title="CTA Linkedin Page"
                      style={{ color: "#4a4a4a" }}
                    >
                      {t("footer.linkedin")}
                    </a>
                    <br />
                  </p>
                </div>
              </div>
              <div className="column is-2">
                <div className="content">
                  <h4>{t("footer.infoPrivacy")}</h4>
                  <p>
                    <Link
                      style={{ color: "#4a4a4a" }}
                      to="/privacy"
                      title="Privacy Policy"
                    >
                      Privacy Policy
                    </Link>
                    <br />
                    <Link
                      style={{ color: "#4a4a4a" }}
                      to="/privacy"
                      title="Cookie Policy"
                    >
                      Cookie Policy
                    </Link>
                  </p>
                </div>
              </div>
              <div className="column is-2"></div>

              <div className="column is-full has-text-centered">
                <FooterNav />
              </div>
              <div
                className="column is-full has-text-centered"
                style={{ borderTop: "1px solid #35b775", paddingTop: "30px" }}
              >
                <div className="content is-small">
                  <p>
                    <b>CTA Manufacturing</b> •{" "}
                    <i>Italian Excellence in Industrial Manufacturing</i> •{" "}
                    {t("footer.marchio")} <a href="https://www.ctaelectronics.com">CTA Electronics Srl</a> • Copyright{" "}
                    {new Date().getFullYear()} • {t("footer.copyright")}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }

export default Footer