import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CookieDialog = props => {

    const { t } = useTranslation()

    // Cookie banner and cookie manager script, loading analytics only is user accepts cookies

    useEffect(() => {

      const COOKIE_NAME_ESSENTIAL = "cookies-essential";
      const COOKIE_NAME_ANALYTICS = "cookies-analytics";
    //   const COOKIE_NAME_YOUTUBE = "cookies-youtube";

      const dialog = document.querySelector("dialog");
      const analyticsCheckbox = dialog.querySelector("[name=analytics]");
      // const youtubeCheckbox = dialog.querySelector("[name=youtube]");

      async function getCookie(name) {
        if (window.cookieStore) {
          const cookie = await window.cookieStore.get(name);
          return cookie ? cookie : null;
        } else {
          const value = document.cookie
            .split("; ")
            .find((row) => row.startsWith(name))
            ?.split("=")[1];
          return value || null;
        }
      }

      async function setCookie(name, value) {
        const expiration = new Date();
        expiration.setMonth(expiration.getMonth() + 6);

        if (window.cookieStore) {
          return window.cookieStore.set({
            name: name,
            value: value,
            expires: expiration,
          });
        } else {
          document.cookie = `${name}=${value}; expires=${expiration.toUTCString()}; samesite=strict; secure`;
        }
      }

      async function checkCookiesAndShowDialog() {
        const analyticsCookie = await getCookie(COOKIE_NAME_ANALYTICS);
        if (analyticsCookie) {
            // console.log('analytics '+analyticsCookie.value+analyticsCookie.expires)
            // The user previously submitted preferences
            // Go ahead and run analytics or load YouTube videos based on the values of the other cookies
            if (analyticsCookie.value === "accept") {
                // Run analytics
                function loadScriptAsync(scriptSrc, callback) {
                    if (typeof callback !== 'function') {
                        throw new Error('Not a valid callback for async script load');
                    }
                    var script = document.createElement('script');
                    script.onload = callback;
                    script.src = scriptSrc;
                    document.head.appendChild(script);
                }

                // We call the above defined function which "calls back" the code, which gets executed after the script has loaded */
            
                loadScriptAsync('https://www.googletagmanager.com/gtag/js?id=G-PESTWTHZ8K', function () {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { window.dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-PESTWTHZ8K', { 'anonymize_ip': true });
                })
            }
          
        } else {
          dialog.show();

          dialog.addEventListener("close", () => {
            const analyticsValue = analyticsCheckbox.checked ? "accept" : "reject";
            // const youtubeValue = youtubeCheckbox.checked ? "accept" : "reject";

            setCookie(COOKIE_NAME_ESSENTIAL, "accept");
            setCookie(COOKIE_NAME_ANALYTICS, analyticsValue);
            // setCookie(COOKIE_NAME_YOUTUBE, youtubeValue);

            // Se i cookie analitici risultano accettati

            if (analyticsValue === "accept") {
              // Run analytics
              function loadScriptAsync(scriptSrc, callback) {
                if (typeof callback !== 'function') {
                  throw new Error('Not a valid callback for async script load');
                }
                var script = document.createElement('script');
                script.onload = callback;
                script.src = scriptSrc;
                document.head.appendChild(script);
              }

              // We call the above defined function which "calls back" the code, which gets executed after the script has loaded */
          
              loadScriptAsync('https://www.googletagmanager.com/gtag/js?id=G-PESTWTHZ8K', function () {
                window.dataLayer = window.dataLayer || [];
                function gtag() { window.dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', 'G-PESTWTHZ8K', { 'anonymize_ip': true });
              })
            }
          });
        }
      };

    // Call the function to check cookies and show the dialog
    checkCookiesAndShowDialog();

    }, []); 
      
    return (
        <dialog className="z-50 rounded-xl p-8" aria-labelledby="dialog-title" aria-describedby="dialog-description">
            <h2 className="bg-slate-100 font-semibold p-2 text-sm rounded-full text-center" id="dialog-label">{t('cookieConsent.title')}</h2>
            <p className="pt-2 text-xs" id="dialog-description">
                {t('cookieConsent.desc')} <a target="_blank" className="text-emerald-500 ring-0 outline-none"
                    href="{{ (.Site.GetPage `privacy`).RelPermalink | relLangURL }}">{t('cookieConsent.privacy')}</a>.
            </p>
            <form method="dialog">
                <div>
                    <label htmlFor="essential"><input type="checkbox" className="rounded-full" name="essential" id="essential"
                            value="essential" aria-describedby="essential-description" checked disabled /> {t('cookieConsent.essentials.title')}</label>
                    <p className="text-xs" id="essential-description">
                        {t('cookieConsent.essentials.desc')}
                    </p>
                </div>
                <div>
                    <label htmlFor="analytics"><input type="checkbox" name="analytics" id="analytics" value="analytics"
                            aria-describedby="analytics-description" /> {t('cookieConsent.analytics.title')}</label>
                    <p className="text-xs" id="analytics-description">
                        {t('cookieConsent.analytics.desc')}
                    </p>
                </div>
                {/* <div>
                <label htmlFor="youtube"><input type="checkbox" name="youtube" id="youtube" value="youtube"
                    aria-describedby="youtube-description" /> YouTube cookies</label>
                <p id="youtube-description">
                These third-party cookies assign a unique identifier to your device. This helps Google to
                collect viewing statistics and to show personalized advertising.
                </p>
            </div>  */}
                <button className="bg-emerald-500 px-8 rounded-full text-white font-semibold py-1">{t('cookieConsent.save')}</button>
            </form>
        </dialog>
    )
}

export default CookieDialog





