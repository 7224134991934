import React from "react"
import { useTranslation } from "react-i18next"
import Link from "../../utilities/link"

const FooterNav = () => {
  const { t } = useTranslation()
  return (
    <div className="navbar" style={{backgroundColor: '#fafafa'}}>
      <div className="container">
        <div className="navbar-brand">
          <span className="navbar-burger burger" data-target="navbarMenuHeroC">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenuHeroC" className="navbar-menu">
          <div
            className="navbar"
            style={{ marginRight: "auto", marginLeft: "auto", backgroundColor: '#fafafa' }}
          >
            <Link
              to="/produzione-industriale-in-10-fasi"
              title={t("header.titleTagProduzione")}
              className="navbar-item"
            >
              {t("header.Produzione")}
            </Link>
            <Link
              to="/strumenti-e-macchinari-produttivi"
              title={t("header.titleTagStrumentazione")}
              className="navbar-item"
            >
              {t("header.Strumentazione")}
            </Link>
            <Link to="/blog" title="Blog" className="navbar-item">
              Blog
            </Link>
            {/* <Link to="/storie-di-successo" className="navbar-item">
              {t("header.StorieDiSuccesso")}
            </Link> */}
            <span className="navbar-item">
              <Link
                to="/contatti"
                title={t("header.titleTagContatti")}
                className="button is-info is-outlined"
              >
                <span> {t("header.Contatti")}</span>
              </Link>
            </span>
            <span className="navbar-item">
              <a
                href="https://www.ctaelectronics.com"
                title="CTA Electronics, Italian Excellence in Industrial Electronics"
                className="button is-primary is-outlined"
              >
                <span> {t("header.SitoPrincipale")}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterNav
